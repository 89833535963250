var red = Object.freeze({
  "colors": {
    "activityBar.background": "#580000",
    "badge.background": "#cc3333",
    "button.background": "#833",
    "debugToolBar.background": "#660000",
    "dropdown.background": "#580000",
    "editor.background": "#390000",
    "editor.foreground": "#F8F8F8",
    "editor.hoverHighlightBackground": "#ff000044",
    "editor.lineHighlightBackground": "#ff000033",
    "editor.selectionBackground": "#750000",
    "editor.selectionHighlightBackground": "#f5500039",
    "editorCursor.foreground": "#970000",
    "editorGroup.border": "#ff666633",
    "editorGroupHeader.tabsBackground": "#330000",
    "editorHoverWidget.background": "#300000",
    "editorLineNumber.activeForeground": "#ffbbbb88",
    "editorLineNumber.foreground": "#ff777788",
    "editorLink.activeForeground": "#FFD0AA",
    "editorSuggestWidget.background": "#300000",
    "editorSuggestWidget.border": "#220000",
    "editorWhitespace.foreground": "#c10000",
    "editorWidget.background": "#300000",
    "errorForeground": "#ffeaea",
    "extensionButton.prominentBackground": "#cc3333",
    "extensionButton.prominentHoverBackground": "#cc333388",
    "focusBorder": "#ff6666aa",
    "input.background": "#580000",
    "inputOption.activeBorder": "#cc0000",
    "inputValidation.infoBackground": "#550000",
    "inputValidation.infoBorder": "#DB7E58",
    "list.activeSelectionBackground": "#880000",
    "list.dropBackground": "#662222",
    "list.highlightForeground": "#ff4444",
    "list.hoverBackground": "#800000",
    "list.inactiveSelectionBackground": "#770000",
    "minimap.selectionHighlight": "#750000",
    "peekView.border": "#ff000044",
    "peekViewEditor.background": "#300000",
    "peekViewResult.background": "#400000",
    "peekViewTitle.background": "#550000",
    "pickerGroup.border": "#ff000033",
    "pickerGroup.foreground": "#cc9999",
    "ports.iconRunningProcessForeground": "#DB7E58",
    "progressBar.background": "#cc3333",
    "quickInputList.focusBackground": "#660000",
    "selection.background": "#ff777788",
    "sideBar.background": "#330000",
    "statusBar.background": "#700000",
    "statusBar.noFolderBackground": "#700000",
    "statusBarItem.remoteBackground": "#c33",
    "tab.activeBackground": "#490000",
    "tab.inactiveBackground": "#300a0a",
    "tab.lastPinnedBorder": "#ff000044",
    "titleBar.activeBackground": "#770000",
    "titleBar.inactiveBackground": "#772222"
  },
  "displayName": "Red",
  "name": "red",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "settings": {
        "foreground": "#F8F8F8"
      }
    },
    {
      "scope": [
        "meta.embedded",
        "source.groovy.embedded",
        "string meta.image.inline.markdown",
        "variable.legacy.builtin.python"
      ],
      "settings": {
        "foreground": "#F8F8F8"
      }
    },
    {
      "scope": "comment",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#e7c0c0ff"
      }
    },
    {
      "scope": "constant",
      "settings": {
        "fontStyle": "",
        "foreground": "#994646ff"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "fontStyle": "",
        "foreground": "#f12727ff"
      }
    },
    {
      "scope": "entity",
      "settings": {
        "fontStyle": "",
        "foreground": "#fec758ff"
      }
    },
    {
      "scope": "storage",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#ff6262ff"
      }
    },
    {
      "scope": "string",
      "settings": {
        "fontStyle": "",
        "foreground": "#cd8d8dff"
      }
    },
    {
      "scope": "support",
      "settings": {
        "fontStyle": "",
        "foreground": "#9df39fff"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#fb9a4bff"
      }
    },
    {
      "scope": "invalid",
      "settings": {
        "foreground": "#ffffffff"
      }
    },
    {
      "scope": "entity.other.inherited-class",
      "settings": {
        "fontStyle": "underline",
        "foreground": "#aa5507ff"
      }
    },
    {
      "scope": "constant.character",
      "settings": {
        "foreground": "#ec0d1e"
      }
    },
    {
      "scope": [
        "string constant",
        "constant.character.escape"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#ffe862ff"
      }
    },
    {
      "scope": "string.regexp",
      "settings": {
        "foreground": "#ffb454ff"
      }
    },
    {
      "scope": "string variable",
      "settings": {
        "foreground": "#edef7dff"
      }
    },
    {
      "scope": "support.function",
      "settings": {
        "fontStyle": "",
        "foreground": "#ffb454ff"
      }
    },
    {
      "scope": [
        "support.constant",
        "support.variable"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#eb939aff"
      }
    },
    {
      "scope": [
        "declaration.sgml.html declaration.doctype",
        "declaration.sgml.html declaration.doctype entity",
        "declaration.sgml.html declaration.doctype string",
        "declaration.xml-processing",
        "declaration.xml-processing entity",
        "declaration.xml-processing string"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#73817dff"
      }
    },
    {
      "scope": [
        "declaration.tag",
        "declaration.tag entity",
        "meta.tag",
        "meta.tag entity"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#ec0d1eff"
      }
    },
    {
      "scope": "meta.selector.css entity.name.tag",
      "settings": {
        "fontStyle": "",
        "foreground": "#aa5507ff"
      }
    },
    {
      "scope": "meta.selector.css entity.other.attribute-name.id",
      "settings": {
        "foreground": "#fec758ff"
      }
    },
    {
      "scope": "meta.selector.css entity.other.attribute-name.class",
      "settings": {
        "fontStyle": "",
        "foreground": "#41a83eff"
      }
    },
    {
      "scope": "support.type.property-name.css",
      "settings": {
        "fontStyle": "",
        "foreground": "#96dd3bff"
      }
    },
    {
      "scope": [
        "meta.property-group support.constant.property-value.css",
        "meta.property-value support.constant.property-value.css"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ffe862ff"
      }
    },
    {
      "scope": [
        "meta.property-value support.constant.named-color.css",
        "meta.property-value constant"
      ],
      "settings": {
        "fontStyle": "",
        "foreground": "#ffe862ff"
      }
    },
    {
      "scope": "meta.preprocessor.at-rule keyword.control.at-rule",
      "settings": {
        "foreground": "#fd6209ff"
      }
    },
    {
      "scope": "meta.constructor.argument.css",
      "settings": {
        "fontStyle": "",
        "foreground": "#ec9799ff"
      }
    },
    {
      "scope": [
        "meta.diff",
        "meta.diff.header"
      ],
      "settings": {
        "fontStyle": "italic",
        "foreground": "#f8f8f8ff"
      }
    },
    {
      "scope": "markup.deleted",
      "settings": {
        "foreground": "#ec9799ff"
      }
    },
    {
      "scope": "markup.changed",
      "settings": {
        "foreground": "#f8f8f8ff"
      }
    },
    {
      "scope": "markup.inserted",
      "settings": {
        "foreground": "#41a83eff"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#f12727ff"
      }
    },
    {
      "scope": "markup.list",
      "settings": {
        "foreground": "#ff6262ff"
      }
    },
    {
      "scope": [
        "markup.bold",
        "markup.italic"
      ],
      "settings": {
        "foreground": "#fb9a4bff"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic"
      }
    },
    {
      "scope": "markup.strikethrough",
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "fontStyle": "",
        "foreground": "#cd8d8dff"
      }
    },
    {
      "scope": [
        "markup.heading",
        "markup.heading.setext",
        "punctuation.definition.heading",
        "entity.name.section"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#fec758ff"
      }
    },
    {
      "scope": [
        "punctuation.definition.template-expression.begin",
        "punctuation.definition.template-expression.end",
        "punctuation.section.embedded",
        ".format.placeholder"
      ],
      "settings": {
        "foreground": "#ec0d1e"
      }
    }
  ],
  "type": "dark"
});

export { red as default };
